import { Box, Button, CardActionArea, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Enrolment } from 'hooks/useMember';
import { useMutate } from 'hooks/useMutate';
import { User } from 'hooks/useUser';
import { useQueryClient } from 'react-query';
import { DisplayImage } from '../display-image';
import { AddButton } from '../add-button';
import { ModalBase } from '../modal-base';
import { useState } from 'react';
import { THEME } from 'Theme';
import { useBrandingContext } from 'components/context/branding-context';

type Props = {
  user: User;
  enrolment: Enrolment;
};

export const GroupUsers = ({ user, enrolment }: Props) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [notFound, setNotFound] = useState(false);
  const { priColor } = useBrandingContext();
  const [email, setEmail] = useState('');

  const { update } = useMutate<string>('/enrolment/assign-group-membership-users', {
    onSuccess: () => {
      queryClient.invalidateQueries(['enrolment']);
      queryClient.invalidateQueries(['member']);
      setOpen(false);
    },
    onError: response => {
      if (response === 'User not found') {
        setNotFound(true);
        setOpen(false);
      }
      if (response === 'Member already assigned') {
        {
          setError('Member already assigned');
        }
      }
    },
  });

  const applicableUsers = [user, ...user.children]?.filter(
    c => !enrolment.groupMembers?.map(i => i.userId).includes(c.id)
  );
  const remainingSlots = enrolment.membership.groupLimit - enrolment.groupMembers?.length;

  const handleUpdate = (targetId?: { id?: number; email?: string }, destroy?: boolean) => {
    if (targetId) {
      update({
        userId: user.id,
        membershipId: enrolment.membershipId,
        targetId,
        destroy,
      });
    }
  };

  return (
    <>
      <ModalBase title={'Not found'} open={!!notFound} onClose={() => setOpen(false)}>
        <Box mt={6} textAlign={'center'}>
          <Box display={'flex'} justifyContent={'center'}>
            <Typography variant="h4" width={'70%'}>
              Member does not exist. Please invite them to create an account and try again.
            </Typography>
          </Box>
          <Box mt={8}>
            <Button
              onClick={() => {
                setNotFound(false);
                navigator.clipboard.writeText(`${window.location.origin}${window.location.pathname}`);
              }}
              variant="contained"
            >
              Copy share link
            </Button>
          </Box>
        </Box>
      </ModalBase>
      <ModalBase title={'Add members'} open={open} onClose={() => setOpen(false)}>
        <Box>
          {!!applicableUsers?.length && (
            <Box sx={{ mt: 4, borderBottom: `solid 1px ${grey[300]}` }}>
              {applicableUsers?.map(u => (
                <CardActionArea
                  sx={{
                    width: 1,
                    bgcolor: priColor,
                    borderRadius: 50,
                    boxShadow: `0.5px 1px 3px ${grey[400]}`,
                    height: 50,
                    marginTop: 2,
                  }}
                  key={u.id}
                  onClick={() => handleUpdate({ id: u.id })}
                >
                  <Box display={'flex'} ml={0} mt={0} width={1}>
                    <DisplayImage sx={{ boxShadow: 'none' }} firstName={u?.firstName} picture={u.picture} size={50} />
                    <Box m={'auto'}>
                      <Typography
                        ml={-6}
                        color={THEME.palette.getContrastText(priColor)}
                        variant="h3"
                      >{`Add ${u?.firstName}`}</Typography>
                    </Box>
                  </Box>
                </CardActionArea>
              ))}
              <Box mx={'auto'} textAlign={'center'} bgcolor={'white'} width={30}>
                <Typography mt={4} mb={-2} variant={'h3'}>
                  OR
                </Typography>
              </Box>
            </Box>
          )}
          <Box mt={4}>
            <TextField
              error={!!error}
              helperText={error}
              value={email}
              sx={{ mt: 3 }}
              label="Enter email address"
              onChange={e => {
                setEmail(e.target.value);
                setError('');
              }}
            />
            <Box textAlign={'right'} mt={4}>
              <Button onClick={() => handleUpdate({ email })} variant="contained">
                Add
              </Button>
            </Box>
          </Box>
        </Box>
      </ModalBase>
      <Box sx={{ mt: 1, borderTop: `solid 1px ${grey[300]}` }}>
        <Typography sx={{ mt: 1 }} variant={'h5'}>
          {`Members${remainingSlots ? ` (${remainingSlots} remaining)` : ''}`}
        </Typography>
        <Box sx={{ my: 1 }}>
          {!enrolment.groupMembers?.length ? (
            <Typography mt={3} mb={4} variant={'h3'}>
              No current members assigned
            </Typography>
          ) : (
            <Box>
              {enrolment.groupMembers?.map(({ user: u }) => (
                <Box key={u.id} justifyContent={'space-between'} display={'flex'} my={2}>
                  <Box display={'flex'}>
                    <DisplayImage firstName={u?.firstName} picture={u.picture} size={30} sx={{ my: 'auto' }} />
                    <Typography my={'auto'} variant="h4" ml={2}>{`${u?.firstName}. ${u?.lastName
                      .substring(0, 1)
                      .toUpperCase()}`}</Typography>
                  </Box>
                  <Button onClick={() => handleUpdate({ id: u.id }, true)} variant="outlined">
                    Remove
                  </Button>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        {!!remainingSlots && (
          <AddButton label="Add member" onClick={() => setOpen(true)} sx={{ mb: 3, maxWidth: 700 }} />
        )}
      </Box>
    </>
  );
};
