import { MenuItem, TextField } from '@mui/material';
import { useSchoolContext } from '../context/school-context';
import { useDocument } from 'hooks/useDocument';
import { DocumentModal } from './document/modal';

type Props = {
  documentId: number | null;
  setDocument: (arg: number | null) => void;
  create?: boolean;
  placeholder?: string;
};

export const DocumentSelect = ({ documentId, setDocument, placeholder, create = true }: Props) => {
  const { school } = useSchoolContext();
  const response = useDocument(school.id);

  return (
    <>
      <DocumentModal open={documentId === 0} onClose={n => setDocument(n || null)} />
      <TextField
        label={placeholder || 'Terms of service'}
        select
        SelectProps={{
          fullWidth: true,
          value: documentId,
          onChange: i => setDocument(i.target.value as number),
          MenuProps: {
            sx: { maxHeight: 240 },
          },
        }}
      >
        <MenuItem key={0} value={undefined}>
          No document
        </MenuItem>
        {create && (
          <MenuItem key={0} value={0}>
            New document
          </MenuItem>
        )}
        {response.data?.map(d => (
          <MenuItem key={d.id} value={d.id}>
            {d.name}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};
