import { Box, TextField, Button } from '@mui/material';
import { useMemo, useState } from 'react';
import { useMutate } from '../../../hooks/useMutate';
import { Resource } from 'hooks/useResource';
import { ModalBase } from 'components/shared/modal-base';

type Props = { resource?: Resource; open: boolean; onClose: () => void };

export const ResourceModal = ({ resource, open, onClose }: Props) => {
  const { create, update, destroy } = useMutate<Resource>('resource', { invalidate: ['resource'] });
  const [name, setName] = useState(resource?.name || '');
  const [description, setDescription] = useState(resource?.description || '');
  const [url, setUrl] = useState(resource?.url || '');

  const disabled = useMemo(() => !name || !url, [name, description, url]);

  const handleCreate = () => {
    if (resource) {
      update({ ...resource, name, url, description });
    } else {
      create({
        name,
        url,
        description,
      });
    }
    onClose();
  };

  return (
    <>
      <ModalBase title={resource ? 'Update resource' : 'New resource'} open={open} onClose={onClose}>
        <Box mt={3}>
          <Box>
            <TextField label={'name'} value={name} onChange={e => setName(e.target.value)}></TextField>
          </Box>
          <Box>
            <TextField
              label={'description'}
              value={description}
              onChange={e => setDescription(e.target.value)}
            ></TextField>
          </Box>
          <Box>
            <TextField label={'link address'} value={url} onChange={e => setUrl(e.target.value)}></TextField>
          </Box>
          <Box mt={4} display={'flex'} justifyContent="space-between">
            <Box>
              {resource && (
                <Button
                  variant="outlined"
                  sx={{ justifySelf: 'start' }}
                  onClick={() => {
                    destroy(resource.id);
                    onClose();
                  }}
                >
                  Delete
                </Button>
              )}
            </Box>
            <Button disabled={disabled} onClick={handleCreate} variant="contained">
              {resource?.id ? 'Save' : 'Create'}
            </Button>
          </Box>
        </Box>
      </ModalBase>
    </>
  );
};
