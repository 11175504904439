import { Session } from '../../../hooks/useClass';
import { Classes } from './classes';
import { ScrollContainer } from 'components/shared/scroll-container';
import { MemberAlerts } from './member-alerts';
import { Referral } from './referral';

export type Schedule = {
  date: string;
  sessions: Session[];
};
export const Dashboard = () => {
  return (
    <ScrollContainer sx={{ mb: 30 }}>
      <Classes />
      <MemberAlerts />
      <Referral />
    </ScrollContainer>
  );
};
