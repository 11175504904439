import { Card, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Box } from '@mui/system';
import { useBrandingContext } from 'components/context/branding-context';
import { CustomModal } from 'components/shared/custom-modal';
import { useState } from 'react';
import { QRCode } from 'react-qrcode-logo';
import ShareIcon from '@mui/icons-material/Share';
import { BrandButton } from 'components/shared/brand-button';
import { useSchoolContext } from 'components/context/school-context';

export const Referral = () => {
  const { school } = useSchoolContext();
  const { logo } = useBrandingContext();
  const [open, setOpen] = useState(false);

  return (
    <>
      <CustomModal open={open} onClose={() => setOpen(false)}>
        <Card
          sx={{
            m: 'auto',
            p: 6,
          }}
        >
          <Box sx={{ borderBottom: `solid 1px ${grey[300]}` }}>
            <Box>
              <QRCode
                size={250}
                eyeRadius={10}
                ecLevel="H"
                qrStyle="squares"
                value={`${window.location.origin}${window.location.pathname}`}
                logoImage={logo}
                removeQrCodeBehindLogo
                logoPadding={4}
              />
            </Box>
            <Box mx={'auto'} textAlign={'center'} bgcolor={'white'} width={30}>
              <Typography mt={4} mb={-2} variant={'h3'}>
                OR
              </Typography>
            </Box>
          </Box>
          <BrandButton
            onClick={() =>
              window.navigator.share({
                title: school?.name,
                url: `${window.location.origin}${window.location.pathname}`,
              })
            }
            sx={{ m: 'auto', mt: 9, width: 250 }}
          >
            <Box m={'auto'} display={'flex'}>
              <Box my={'auto'}>{'Share link'}</Box>
              <Box ml={1}>
                <ShareIcon />
              </Box>
            </Box>
          </BrandButton>
        </Card>
      </CustomModal>
      <BrandButton onClick={() => setOpen(true)}>{'Invite a friend'}</BrandButton>
    </>
  );
};
