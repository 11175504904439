import { Card, Typography, Box, Button, CircularProgress } from '@mui/material';
import { CustomModal } from 'components/shared/custom-modal';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { Document as PDF, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useRef, useState } from 'react';
import { Document } from 'hooks/useDocument';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {
  open: boolean;
  onClose: () => void;
  document: Document;
  onSubmit?: () => void;
};

export const TermsAndConditionsModal = ({ open, onClose, onSubmit, document }: Props) => {
  const { small } = useCustomTheme();
  const [totalPages, setTotalPages] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const [scale] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setTotalPages(numPages);
  };

  return (
    <>
      <CustomModal open={open} onClose={onClose}>
        <Card
          sx={{
            width: small ? 400 : 600,
            m: 'auto',
            p: 2,
            justifyItems: 'center',
            height: '80vh',
          }}
        >
          <Box
            ref={ref}
            sx={{
              width: small ? 390 : 600,
              height: '80vh',
              overflow: 'auto',
              pb: 16,
            }}
          >
            <Box display={'flex'} justifyContent={'space-between'} mt={3} ml={3}>
              <Typography variant="h3">{document?.name}</Typography>
            </Box>
            <PDF
              loading={
                <Box width={1} height={'80vh'} display={'flex'}>
                  <CircularProgress sx={{ margin: 'auto' }} />
                </Box>
              }
              file={document?.url}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(totalPages), (el, index) => (
                <Page width={small ? 390 : 600} scale={scale} key={index} pageNumber={index + 1} />
              ))}
            </PDF>
            <Button></Button>
          </Box>
          <Box mt={-18} ml={-6} width={1} textAlign={'end'}>
            {onSubmit && (
              <Button variant="contained" sx={{ ml: 6, zIndex: 999 }} onClick={onSubmit}>
                {'Accept'}
              </Button>
            )}
          </Box>
        </Card>
      </CustomModal>
    </>
  );
};
