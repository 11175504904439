import { Box, SxProps } from '@mui/material';

export const ScrollContainer = ({
  children,
  fullWidth = false,
  sx = {},
}: {
  children: JSX.Element | JSX.Element[];
  fullWidth?: boolean;
  sx?: SxProps;
}) => (
  <Box pt={6} height={1} overflow={'scroll'} sx={{ maxWidth: fullWidth ? null : 1200, ...sx }}>
    <Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'} mb={30}>
      {children}
    </Box>
  </Box>
);
