import { Grid, Box, Typography, Button } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import useDraggableScroll from 'use-draggable-scroll';
import { useSchoolContext } from 'components/context/school-context';
import { MembershipCard } from 'components/shared/membership-card/membership-card';
import { Membership, useMembership } from 'hooks/useMemership';
import { CustomModal } from 'components/shared/custom-modal';
import { User } from 'hooks/useUser';
import { useMutate } from 'hooks/useMutate';
import { UserDetailsModal } from '../user-details-modal';
import { useUserContext } from 'components/context/user-context';
import { CardBase } from 'components/shared/card-base';
import { TermsAndConditionsModal } from '../terms-and-conditions-modal';

type EnrolUser = { user: User; membership: Membership };

type Props = {
  open: boolean;
  user: User;
  admin?: boolean;
  onClose: () => void;
};

export const EnrolmentModal = ({ open, user, admin, onClose }: Props) => {
  const { school } = useSchoolContext();
  const { user: contextUser } = useUserContext();
  const ref = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref);
  const useMembershipResponse = useMembership(school.id);
  const availableMemberships = useMemo(() => useMembershipResponse.data, [useMembershipResponse.data]);
  const [userDetailsPresent] = useState(
    !!(user.provider === 'parent' || user.phone) && !!user.emergencyContact && !!user.birthday
  );

  const [enrolUser, setEnrolUser] = useState<EnrolUser>();
  const [modal, setModel] = useState({ terms: false, userDetails: false });
  const { create, response } = useMutate<string>('enrolment', {
    invalidate: ['enrolment'],
    onSuccess: () => setEnrolUser(undefined),
    headers: { href: location.href, ...(admin && { impersonator: contextUser?.id }) },
  });
  const { update } = useMutate('user', {
    ...(admin && { headers: { impersonator: contextUser?.id } }),
    invalidate: ['member'],
  });

  useEffect(() => {
    if (!!response) {
      window.location.assign(response);
    }
  }, [response]);

  const handleEnrol = (userId: number, membershipId: number) => {
    create({ userId, membershipId });
    setEnrolUser(undefined);
  };

  const handleSubmit = (data: EnrolUser) => {
    setEnrolUser(data);
    if (userDetailsPresent && !data?.membership.document) {
      create({ userId: data.user.id, membershipId: data.membership.id });
      setEnrolUser(undefined);
    } else if (!userDetailsPresent) {
      setModel({ userDetails: true, terms: false });
    } else if (data?.membership.document) {
      setModel({ userDetails: false, terms: true });
    }
  };

  const reset = () => {
    setModel({ userDetails: false, terms: false });
    setEnrolUser(undefined);
  };

  return (
    <>
      <>
        {enrolUser && (
          <>
            <UserDetailsModal
              open={modal.userDetails}
              onClose={reset}
              onSubmit={data => {
                if (data) {
                  update(data);
                }
                if (!enrolUser?.membership.document) {
                  handleEnrol(enrolUser.user.id, enrolUser.membership.id);
                } else {
                  setModel({ userDetails: false, terms: true });
                }
              }}
              user={enrolUser.user}
            />
            {enrolUser?.membership?.document && (
              <TermsAndConditionsModal
                open={modal.terms}
                onClose={reset}
                onSubmit={() => handleEnrol(enrolUser.user.id, enrolUser.membership.id)}
                document={enrolUser?.membership.document}
              />
            )}
          </>
        )}
      </>
      <CustomModal open={open} onClose={onClose}>
        <Grid container direction={'column'} m={'auto'}>
          <Grid container justifyContent="center">
            <Box
              sx={{
                overflow: 'scroll',
                display: 'flex',
                scrollSnapType: 'x mandatory',
              }}
              ref={ref}
              onMouseDown={onMouseDown}
            >
              {availableMemberships?.length ? (
                availableMemberships.map(m => (
                  <MembershipCard membership={m} key={m.id}>
                    <Box textAlign={'center'}>
                      <Button variant="contained" onClick={() => handleSubmit({ user, membership: m })}>
                        {`Sign up ${user.firstName}`}
                      </Button>
                    </Box>
                  </MembershipCard>
                ))
              ) : (
                <CardBase sx={{ p: 6, pb: 3 }}>
                  <Typography variant="h2" mb={4}>
                    No available memberships
                  </Typography>
                </CardBase>
              )}
            </Box>
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};
