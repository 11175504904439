import { Button, Checkbox, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ModalBase } from 'components/shared/modal-base';
import { useMemo, useState } from 'react';
import { Document } from '../../../../hooks/useDocument';
import { DeleteButton } from 'components/shared/delete-button';
import { useMutate } from 'hooks/useMutate';
import { useQueryClient } from 'react-query';

type Props = {
  open: boolean;
  onClose: (arg?: number) => void;
  document?: Document;
};

export const DocumentModal = ({ open, onClose, document }: Props) => {
  const queryClient = useQueryClient();
  const [name, setName] = useState<string>(document ? document.name : '');
  const [description, setDescription] = useState<string>(document ? document.description : '');
  const [upversion, setUpversion] = useState<boolean>(false);
  const { create, update, destroy } = useMutate('document', {
    onSuccess: (doc: Document) => {
      queryClient.invalidateQueries('document');
      onClose(doc.id);
    },
  });
  const [file, setFile] = useState<File>();

  const handleFileChange = async (fileList: FileList | null) => {
    if (fileList) {
      setFile(fileList[0]);
    }
  };

  const showCheckbox = useMemo(() => !!document && !!file, [document, file]);

  const handleSave = () => {
    if (document) {
      const formData = new FormData();
      formData.append('id', document.id.toString());
      if (file) {
        formData.append('file', file);
      }
      formData.append('name', name);
      formData.append('description', description);
      formData.append('upversion', upversion.toString());
      update(formData);
    } else if (file && name && description) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', name);
      formData.append('description', description);
      create(formData);
    }
  };

  const handleDelete = () => {
    if (document) {
      destroy(document.id);
      onClose();
    }
  };

  const disabled = useMemo(
    () => (document ? !name || !description : !name || !description || !file?.size),
    [name, description, file]
  );

  return (
    <ModalBase open={open} onClose={onClose} title={document ? 'Edit Document' : 'Add Document'}>
      <Box mt={3}>
        <TextField label={'name'} value={name} onChange={e => setName(e.target.value)}></TextField>
        <TextField label={'description'} value={description} onChange={e => setDescription(e.target.value)}></TextField>
        <Box display={'flex'}>
          <Box width={140} mt={2} display={'flex'} mx={'auto'}>
            <Button variant="contained" sx={{ my: 'auto' }} component="label">
              {document ? 'New file' : 'Select file'}
              <input type="file" accept="application/pdf" hidden onChange={e => handleFileChange(e.target.files)} />
            </Button>
          </Box>
          <TextField placeholder="no file selected" value={file?.name}></TextField>
        </Box>
        {showCheckbox && (
          <Box display={'flex'} mt={3}>
            <Checkbox checked={upversion} onChange={i => setUpversion(i.currentTarget.checked)} />
            <Typography sx={{ my: 'auto', ml: 2 }}>{'Force members to accept new version?'}</Typography>
          </Box>
        )}
        <Box mt={6} display={'flex'} justifyContent={'space-between'}>
          <DeleteButton onClick={handleDelete} />
          <Button disabled={disabled} variant="contained" onClick={handleSave}>
            {'Save'}
          </Button>
        </Box>
      </Box>
    </ModalBase>
  );
};
