import { Typography, CardActionArea, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { CardBase } from 'components/shared/card-base';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { pdfjs } from 'react-pdf';
import EditIcon from '@mui/icons-material/Edit';
import { Document } from 'hooks/useDocument';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const DocumentCard = ({
  document,
  onClick,
  onEdit,
}: {
  document: Document;
  onClick: (arg: Document) => void;
  onEdit: (arg: Document) => void;
}) => {
  const { cardWidth } = useCustomTheme();

  return (
    <>
      <Box display={'flex'} m={3}>
        <CardActionArea sx={{ borderRadius: 3 }} onClick={() => onClick(document)}>
          <CardBase sx={{ display: 'flex', height: 70, width: cardWidth }}>
            <Box width={1} display={'flex'} justifyContent={'space-between'}>
              <Box p={3} my={'auto'}>
                <Box display={'flex'}>
                  <Typography variant="h3" mb={1}>
                    {document.name}
                  </Typography>
                </Box>
                <Box display={'flex'}>
                  <Typography variant="h5" mb={1}>
                    {document.description}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardBase>
        </CardActionArea>
        <Box ml={-7.5} mt={1}>
          <IconButton size="small" onClick={() => onEdit(document)}>
            <EditIcon sx={{ height: 18, width: 18 }} />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};
