import { Box, Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { useSchoolMembers } from 'hooks/admin/useSchoolMembers';
import { useSchoolContext } from 'components/context/school-context';
import { UserButton } from 'components/shared/user-button';
import { useSchoolMember } from 'hooks/admin/useSchoolMember';
import { useMemo, useState } from 'react';
import { MemberCard } from './member-card';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import CancelIcon from '@mui/icons-material/Cancel';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { AddButton } from 'components/shared/add-button';
import { User } from 'hooks/useUser';
import { MemberModal } from 'components/admin/member-settings/member-modal';
import { MemberFilter } from './member-filter';
import { CreateUserDto, UpdateMemberDto } from 'utils/types';
import { useMutate } from 'hooks/useMutate';
import { useCohorts } from 'hooks/useCohorts';
import { useMembership } from 'hooks/useMemership';
import { ChildModal } from 'components/modals/child-modal';
import { useQueryClient } from 'react-query';
import { useQueryState } from 'hooks/useQueryState';

export const Members = () => {
  const { school } = useSchoolContext();
  const { cardWidth } = useCustomTheme();
  const [{ searchTerm = '', userId }, setQuery, remove] = useQueryState();
  const { data: cohorts } = useCohorts(school.id);
  const { data: memberships } = useMembership(school.id);
  const queryClient = useQueryClient();
  const [memberModalOpen, setMemberModalOpen] = useState(false);
  const [anchor, setAnchor] = useState<HTMLButtonElement>();
  const { data: schoolMembers = [] } = useSchoolMembers(school.id);
  const memberResponse = useSchoolMember(school.id, +userId || undefined);

  const [open, setOpen] = useState(false);
  const { create } = useMutate<UpdateMemberDto>('admin/member/child', {
    onSuccess: () => {
      queryClient.invalidateQueries(['member']);
      queryClient.invalidateQueries(['members']);
    },
  });

  const handleCreateChild = (parent: User, data: CreateUserDto) => {
    create({ ...data, parent: parent.id });
  };

  const statusWeight: Record<string, number> = {
    active: 1,
    prospect: 2,
    inactive: 0,
  };

  const [cohortFilters, setCohortFilters] = useState<number[]>([]);
  const [statusFilters, setStatusFilters] = useState<string[]>([]);
  const filtersApplied = cohortFilters.length || statusFilters.length;

  const cohortfilteredMembers = useMemo(() => {
    return schoolMembers?.filter(s =>
      cohortFilters.length ? s.cohorts?.some(c => cohortFilters.includes(c?.id)) : true
    );
  }, [schoolMembers, cohortFilters]);

  const statusfilteredMembers = useMemo(() => {
    return cohortfilteredMembers?.filter(s => (statusFilters.length ? statusFilters?.some(i => i === s.status) : true));
  }, [cohortfilteredMembers, statusFilters]);

  const searchedMembers = useMemo(
    () =>
      searchTerm
        ? statusfilteredMembers?.filter(i =>
            [`${i.user.firstName} ${i.user.lastName}`, i.user.lastName, i.user.email].some(
              v =>
                searchTerm &&
                !!v?.toLowerCase().match(new RegExp(`(^${searchTerm.replace(/[^a-zA-Z0-9 ]/g, '')})`, 'g'))
            )
          ) || []
        : statusfilteredMembers,
    [searchTerm, statusfilteredMembers]
  )
    .sort((a, b) => a.user.firstName.localeCompare(b.user.firstName))
    .sort((a, b) => statusWeight[b.status] - statusWeight[a.status]);

  if (!schoolMembers) {
    return <></>;
  }

  return (
    <Box mb={24} maxWidth={1200} justifyContent={'center'}>
      <MemberModal open={memberModalOpen} onClose={() => setMemberModalOpen(false)} />
      <Box mt={6} display={'flex'} width={1} justifyContent={'center'}>
        <Box>
          <Box display={'flex'} sx={{ width: cardWidth }}>
            <IconButton sx={{ height: 38, width: 38, mt: 4 }} onClick={e => setAnchor(e.currentTarget)}>
              <TuneIcon sx={{ color: school.priColor }} />
            </IconButton>

            {cohorts && memberships && (
              <MemberFilter
                cohorts={cohorts}
                cohortFilters={cohortFilters}
                statusFilters={statusFilters}
                onCohortChange={setCohortFilters}
                onStatusChange={setStatusFilters}
                anchor={anchor}
                onClose={() => setAnchor(undefined)}
              />
            )}
            <TextField
              key={userId}
              autoComplete="off"
              value={
                userId && memberResponse.data
                  ? `${memberResponse.data?.user.firstName} ${memberResponse.data?.user.lastName}`
                  : searchTerm
              }
              onChange={({ currentTarget }) => {
                setQuery({ searchTerm: currentTarget.value.toLowerCase().replace(' ', '%20') });
              }}
              sx={{ width: cardWidth }}
              InputProps={{
                endAdornment: (
                  <>
                    {!!userId && (
                      <InputAdornment position="start">
                        <IconButton sx={{ mr: -4 }} onClick={() => remove(['userId'])}>
                          <CancelIcon />
                        </IconButton>
                      </InputAdornment>
                    )}
                    <InputAdornment position="end">
                      <IconButton sx={{ mr: -4 }}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </Box>
          <Box height={32}>
            {(!!filtersApplied || searchTerm) && (
              <Box display={'flex'} width={1} justifyContent={'center'}>
                <Button
                  variant="text"
                  onClick={() => {
                    setCohortFilters([]);
                    setStatusFilters([]);
                    remove(['searchTerm']);
                  }}
                >
                  Reset filters
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {memberResponse.data && (
        <Grid container mb={24} maxWidth={1200} justifyContent={'center'}>
          <ChildModal
            open={open}
            onCreate={i => handleCreateChild(memberResponse.data.user, i)}
            onClose={() => setOpen(false)}
          />
          <MemberCard user={memberResponse.data.user} member={memberResponse.data} />
          {memberResponse.data.user?.children.map(c => (
            <MemberCard key={c.id} member={memberResponse.data.children.find(i => i.userId === c.id)} user={c} />
          ))}
          <AddButton label="Add child" onClick={() => setOpen(v => !v)} sx={{ m: 4, width: cardWidth }} />
        </Grid>
      )}

      <Grid mb={24} container maxWidth={1200} justifyContent={'center'} alignContent={'center'}>
        {!memberResponse.data && (
          <>
            <AddButton sx={{ m: 3, width: cardWidth }} label={'Add member'} onClick={() => setMemberModalOpen(true)} />
            {searchedMembers?.map((m, idx) => (
              <Box key={idx} sx={{ m: 3 }}>
                <UserButton member={m} onClick={() => setQuery({ userId: `${m.user?.id}` })} />
              </Box>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );
};
