import { Box, Button, Card, Grid, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { DatePicker } from '@mui/x-date-pickers';
import { useSchoolContext } from 'components/context/school-context';
import { CustomModal } from 'components/shared/custom-modal';
import { DeleteButton } from 'components/shared/delete-button';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { Member } from 'hooks/useMember';
import { useMutate } from 'hooks/useMutate';
import { useQueryState } from 'hooks/useQueryState';
import { User } from 'hooks/useUser';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { iso } from 'utils/time';
import { UpdateMemberDto } from 'utils/types';

type Props = {
  user?: User;
  member?: Member;
  open: boolean;
  onClose: () => void;
};

export const MemberModal = ({ user, member, open, onClose }: Props) => {
  const { small } = useCustomTheme();
  const { school } = useSchoolContext();
  const queryClient = useQueryClient();
  const [, , remove] = useQueryState();
  const [error, setError] = useState('');
  const { create } = useMutate<UpdateMemberDto>('admin/member', {
    onSuccess: () => {
      queryClient.invalidateQueries(['member']);
      queryClient.invalidateQueries(['members']);
      setError('');
      onClose();
    },
    onError: resp => setError(resp),
  });
  const { destroy } = useMutate('admin/member', {
    onSuccess: () => {
      remove(['userId']);
      queryClient.invalidateQueries(['members']);
    },
  });
  const [email, setEmail] = useState(user?.email || '');
  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
  const [phone, setPhone] = useState(user?.phone || '');
  const [emergencyName, setEmergencyName] = useState(user?.emergencyContact?.name || '');
  const [emergencyPhone, setEmergencyPhone] = useState(user?.emergencyContact?.phone || '');
  const [birthday, setBirthday] = useState<DateTime | null>(user?.birthday ? iso(user?.birthday) : null);
  const isChild = user?.provider === 'parent';

  const dataValid = (isChild || phone) && emergencyName && emergencyPhone && birthday;
  const handleSubmit = () => {
    if (dataValid) {
      const data = {
        id: user?.id,
        firstName,
        lastName,
        email,
        phone,
        birthday: birthday.toISODate(),
        emergencyContact: { name: emergencyName, phone: emergencyPhone },
        ...(!isChild && { phone }),
      };
      create({ userId: user?.id, schoolId: school.id, user: data });
    }
  };

  return (
    <CustomModal open={open} onClose={onClose}>
      <Card
        sx={{
          display: 'flex',
          width: small ? '90vw' : '450px',
          m: 'auto',
          p: 8,
          backgroundColor: grey[100],
        }}
      >
        <Grid container>
          <Typography variant="h3" mb={2}>
            {user ? `Update details for ${user.firstName}` : 'New user'}
          </Typography>
          <TextField label={'first name'} value={firstName} onChange={e => setFirstName(e.target.value)}></TextField>
          <TextField label={'last name'} value={lastName} onChange={e => setLastName(e.target.value)}></TextField>
          {!isChild && (
            <TextField
              error={!!error}
              helperText={error}
              label={'email'}
              value={email}
              onChange={e => setEmail(e.target.value)}
            ></TextField>
          )}
          {!isChild && <TextField label={'phone'} value={phone} onChange={e => setPhone(e.target.value)} />}
          <TextField
            label={'emergency contact name'}
            value={emergencyName}
            onChange={e => setEmergencyName(e.target.value)}
          />
          <TextField
            label={'emergency contact phone'}
            value={emergencyPhone}
            onChange={e => setEmergencyPhone(e.target.value)}
          />
          <DatePicker
            label={'birthday'}
            format={'dd/MM/yyyy'}
            onChange={d => {
              setBirthday(d as DateTime);
            }}
            value={birthday}
          />
          <Box mt={4} width={1} display={'flex'} justifyContent={'space-between'}>
            <Box>
              {['prospect', 'inactive'].includes(member?.status || '') && (
                <DeleteButton
                  onClick={() => {
                    destroy(user?.id);
                  }}
                />
              )}
            </Box>
            <Button disabled={!dataValid} variant="contained" onClick={handleSubmit}>
              {user ? 'Update' : 'Create'}
            </Button>
          </Box>
        </Grid>
      </Card>
    </CustomModal>
  );
};
