import { Card, CardActionArea, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { SxProps } from '@mui/system';
import { useBrandingContext } from 'components/context/branding-context';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { THEME } from 'Theme';

export const BrandButton = ({
  children,
  onClick,
  sx = {},
}: {
  children: string | JSX.Element[] | JSX.Element;
  onClick: () => void;
  sx?: SxProps;
}) => {
  const { priColor } = useBrandingContext();
  const { cardWidth } = useCustomTheme();

  return (
    <Card
      sx={{
        width: cardWidth,
        boxShadow: `0.5px 1px 3px ${grey[400]}`,
        height: 50,
        bgcolor: priColor,
        ...sx,
      }}
    >
      <CardActionArea onClick={onClick} sx={{ px: 1, height: 50 }}>
        <Typography
          variant="h2"
          sx={{ display: 'flex', justifyContent: 'center', color: THEME.palette.getContrastText(priColor) }}
        >
          {children}
        </Typography>
      </CardActionArea>
    </Card>
  );
};
