import { Box, Typography } from '@mui/material';
import { useSchoolContext } from 'components/context/school-context';
import { BarChart } from '@mui/x-charts';
import { CardBase } from 'components/shared/card-base';
import { Transaction } from 'hooks/admin/useTransactions';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { sumBy } from 'lodash';
import { DateTime } from 'luxon';
import { grey } from '@mui/material/colors';
import { ValueBox } from '../value-box';
import { useMemo } from 'react';
import { chartPalette } from 'Theme';

type Props = {
  transactions: Transaction[];
};

export const Body = ({ transactions }: Props) => {
  const { small } = useCustomTheme();
  const { school } = useSchoolContext();

  const now = DateTime.now();
  const yearStart = now.startOf('year');
  const ranges = useMemo(
    () =>
      new Array(12).fill(0).map((_, i) => {
        const date = yearStart.plus({ months: i });
        return {
          date: date.monthShort,
          v1: sumBy(
            transactions.filter(
              d => d.arrival_date <= date.toSeconds() && d.arrival_date > date.minus({ months: 1 }).toSeconds()
            ),
            d => d.amount / 100
          ),
          v2: sumBy(
            transactions.filter(
              d =>
                d.arrival_date <= date.minus({ year: 1 }).toSeconds() &&
                d.arrival_date > date.minus({ months: 1, year: 1 }).toSeconds()
            ),
            d => d.amount / 100
          ),
        };
      }),
    [transactions]
  );

  const week = useMemo(
    () =>
      transactions
        .filter(d => d.arrival_date <= now.toSeconds() && d.arrival_date > now.minus({ days: 7 }).toSeconds())
        .reduce((acc, d) => acc + d.amount / 100, 0),
    [transactions]
  );

  const month = useMemo(
    () =>
      transactions
        .filter(d => d.arrival_date <= now.toSeconds() && d.arrival_date > now.minus({ days: 30 }).toSeconds())
        .reduce((acc, d) => acc + d.amount / 100, 0),
    [transactions]
  );

  return (
    <Box>
      <Box display={'flex'}>
        <ValueBox title={'Last 7 days'} value={`$${week.toFixed(2) || 0}`} />
        <ValueBox title={'Last 30 days'} value={`$${month.toFixed(2) || 0}`} />
      </Box>
      <CardBase sx={{ p: 3, height: 320, maxWidth: 600, width: small ? '92vw' : '60vw', m: 3 }}>
        <Box mt={1}>
          <Typography mb={0} color={grey[600]}>
            {'Revenue'}
          </Typography>
          <BarChart
            dataset={ranges}
            yAxis={[
              {
                scaleType: 'linear',
                valueFormatter: (v: number) => {
                  return v / 1000 >= 1 ? `$${(v / 1000).toFixed(1)}k` : `$${v}`;
                },
              },
            ]}
            xAxis={[
              {
                scaleType: 'band',
                dataKey: 'date',
              },
            ]}
            colors={[school.priColor, ...chartPalette]}
            height={260}
            sx={{ pr: 0 }}
            series={[
              {
                valueFormatter: value => `$${value?.toFixed(2)}`,
                data: ranges.map(i => i.v1),
                label: now.year.toString(),
              },
              {
                valueFormatter: value => `$${value?.toFixed(2)}`,
                data: ranges.map(i => i.v2),
                label: now.minus({ year: 1 }).year.toString(),
              },
            ]}
            borderRadius={10}
            margin={{
              left: 40,
              right: 10,
              top: 60,
              bottom: 20,
            }}
            slotProps={{
              legend: {
                hidden: false,
                labelStyle: {
                  fontSize: 10,
                },
                itemMarkWidth: 8,
                itemMarkHeight: 8,
                markGap: 5,
                itemGap: 15,
                position: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              },
            }}
          />
        </Box>
      </CardBase>
    </Box>
  );
};
