import { Box, CircularProgress } from '@mui/material';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useSchoolContext } from 'components/context/school-context';
import { DocumentCard } from 'components/shared/document/card';
import { Document, useDocument } from 'hooks/useDocument';
import { useState } from 'react';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { DocumentModal } from 'components/shared/document/modal';
import { ScrollContainer } from 'components/shared/scroll-container';
import { AddButton } from 'components/shared/add-button';
import { TermsAndConditionsModal } from 'components/modals/terms-and-conditions-modal';

export const Documents = () => {
  const { school } = useSchoolContext();
  const { cardWidth } = useCustomTheme();
  const [viewDocument, setViewDocument] = useState<Document>();
  const [editDocument, setEditDocument] = useState<Document>();
  const [open, setOpen] = useState(false);

  const { data, isFetching } = useDocument(school.id);

  const handleEdit = (document: Document) => {
    setEditDocument(document);
    setOpen(true);
  };

  if (isFetching && !data) {
    return (
      <Box width={200} textAlign={'center'}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <DocumentModal
        key={editDocument?.id || 'school'}
        open={open}
        onClose={() => {
          setEditDocument(undefined);
          setOpen(false);
        }}
        document={editDocument}
      />
      {!!viewDocument ? (
        <TermsAndConditionsModal open={!!document} onClose={() => setViewDocument(undefined)} document={viewDocument} />
      ) : (
        <>
          <ScrollContainer>
            <>
              {data
                ?.sort((a, b) => a.id - b.id)
                .map(document => (
                  <DocumentCard key={document.id} document={document} onClick={setViewDocument} onEdit={handleEdit} />
                ))}
            </>
            <AddButton label={'Add document'} onClick={() => setOpen(true)} sx={{ m: 3, width: cardWidth }} />
          </ScrollContainer>
        </>
      )}
    </>
  );
};
