import { Card, Box, Typography, ButtonBase, Button, IconButton } from '@mui/material';
import { AuthWrapper } from 'components/auth-wrapper';
import { useSchoolContext } from 'components/context/school-context';
import { useUserContext } from 'components/context/user-context';
import { CreateClassModal } from 'components/modals/create-class-modal';
import { CustomModal } from 'components/shared/custom-modal';
import { DisplayImage } from 'components/shared/display-image';
import { Session } from 'hooks/useClass';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { useMutate } from 'hooks/useMutate';
import { useQueryState } from 'hooks/useQueryState';
import { groupBy } from 'lodash';
import { useMemo, useState } from 'react';
import { byValidAge } from 'utils/eligibility';
import { validateAttendanceWindow, friendlyTime, iso } from 'utils/time';
import { ActiveStatus, PageType, Tabs } from 'utils/types';
import EditIcon from '@mui/icons-material/Edit';
import success from '../../../assets/success.mp3';
import { Attendance } from 'hooks/useAttendance';
import { grey } from '@mui/material/colors';
import { CheckInButton } from '../../shared/checkin-button';
import { TermsAndConditionsModal } from 'components/modals/terms-and-conditions-modal';
import { MemberAttendance } from 'components/admin/member-attendance';

type Props = {
  session: Session;
  currentClass: { date: string; session?: Session };
  attendance: Attendance[];
  open: boolean;
  onClose: () => void;
};

export const ClassModal = ({ open, onClose, session, attendance, currentClass }: Props) => {
  const [, setQuery] = useQueryState();
  const { small } = useCustomTheme();
  const { users } = useUserContext();
  const { member } = useSchoolContext();
  const [unsignedUserId, setUnsignedUserId] = useState<number>();

  const userEnrolments = useMemo(
    () =>
      groupBy(
        [member, ...member.children]
          .map(m =>
            [m.enrolments.filter(e => e.membership.type != 'group_membership'), m.groupEnrolments]
              .flat()
              .filter(e => ActiveStatus.includes(e.status))
              .map(e => ({
                key: m.userId,
                ...e,
              }))
          )
          .flat(),
        'key'
      ),
    [member]
  );

  const userAttendance = groupBy(attendance, i => i.userId);
  const eligibleUsers = useMemo(() => byValidAge(users, session.subject), [session]);
  const classTime = useMemo(() => `${currentClass.date}T${iso(session.dateTime).toISOTime()}`, [currentClass, session]);

  const enrolledUsers = useMemo(
    () =>
      eligibleUsers.filter(u =>
        userEnrolments[u.id]?.some(
          e =>
            e.membership.locations.some(l => l.id === session.location.id) &&
            e.membership.subjects.some(s => session.subject.map(x => x.id).includes(s.id)) &&
            (!e.membership.type.includes('membership') ||
              (e.membership.attendanceLimit
                ? validateAttendanceWindow(currentClass.date, userAttendance[u.id], e.membership)
                : true))
        )
      ),
    [session]
  );

  const [createModal, setCreateModal] = useState(false);
  const { create: book } = useMutate<Attendance>('attendance', {
    invalidate: ['attendance'],
    onSuccess: (x: Attendance) => {
      if (!x.cancelled) new Audio(success).play();
    },
  });

  const { create: signTerms } = useMutate('/member/terms', {
    invalidate: ['member'],
    onSuccess: () => {
      book({ userId: unsignedUserId, classId: session.id, date: classTime });
      setUnsignedUserId(undefined);
    },
  });

  const [{ document }] = session.subject;

  const handleBook = (userId: number) => {
    if ((document && member.acceptedTerms[document.id] === document.version) || !document) {
      book({ userId, classId: session.id, date: classTime });
    } else {
      setUnsignedUserId(userId);
    }
  };

  const hideAttendance = small || !member.isAdmin;

  return (
    <>
      <CreateClassModal
        date={currentClass.date}
        open={createModal}
        close={() => setCreateModal(false)}
        session={session}
      />
      {document && (
        <TermsAndConditionsModal
          open={!!unsignedUserId}
          onSubmit={() => signTerms({ id: document.id })}
          onClose={() => setUnsignedUserId(undefined)}
          document={document}
        />
      )}
      <CustomModal open={open} onClose={onClose}>
        <Card sx={{ width: '95vw', maxWidth: hideAttendance ? 350 : 700, display: 'flex', m: 'auto', p: 6 }}>
          <Box width={1} display={small ? 'block' : 'flex'}>
            <Box
              width={'50%'}
              borderRight={`solid 1px ${grey[300]}`}
              sx={{
                width: '50%',
                pr: 6,
                borderRight: `solid 1px ${grey[300]}`,
                ...(hideAttendance && {
                  width: 1,
                  pr: 0,
                  borderRight: 'none',
                }),
              }}
            >
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Box>
                    {session.subject.map(s => (
                      <Typography variant="h2" textAlign={'left'} key={s.id}>
                        {s.name}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              </Box>
              <Typography variant="h4" sx={{ mt: 1 }}>
                {`${friendlyTime(session.dateTime)} - ${friendlyTime(
                  iso(session.dateTime).plus({ minutes: session.duration }).toISO()
                )}`}
              </Typography>
              <Typography variant="h3" color={session.location.color}>
                {session.location.description}
              </Typography>
              <Typography variant="h5">{session.location.address}</Typography>
              <Box
                display={'flex'}
                maxHeight={small ? 120 : 1}
                mt={2}
                pt={2}
                borderTop={`solid 1px ${grey[300]}`}
                overflow={'scroll'}
              >
                {session.subject.map(s => (
                  <Typography key={s.id} mb={2} variant="h5">
                    {s.description}
                  </Typography>
                ))}
              </Box>
              {eligibleUsers.length === 0 ? (
                <Box borderTop={`solid 1px ${grey[300]}`} textAlign={'center'}>
                  <Typography variant="h4" mt={5}>
                    You are not eligible for this class
                  </Typography>
                </Box>
              ) : (
                <Box mb={3} textAlign={'center'}>
                  {enrolledUsers.length ? (
                    <Box borderTop={`solid 1px ${grey[300]}`}>
                      {enrolledUsers.length === 1 ? (
                        <CheckInButton
                          name={enrolledUsers[0].firstName}
                          active={session.attendance.includes(enrolledUsers[0]?.id)}
                          onClick={() => handleBook(enrolledUsers[0]?.id)}
                        />
                      ) : (
                        <>
                          <Typography variant="h5" mt={2}>
                            Select attendees
                          </Typography>
                          <Box display={'flex'} justifyContent={'center'}>
                            <Box mt={4} height={50} display={'flex'} overflow={'scroll'}>
                              {enrolledUsers.map(i => (
                                <ButtonBase
                                  key={i.id}
                                  onClick={() => handleBook(i.id)}
                                  sx={{ mx: 2, borderRadius: 50 }}
                                >
                                  <DisplayImage
                                    firstName={i.firstName}
                                    picture={i.picture}
                                    size={40}
                                    selected={session.attendance.includes(i.id)}
                                  />
                                </ButtonBase>
                              ))}
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                  ) : (
                    <>
                      <Button
                        variant="text"
                        onClick={() => {
                          setQuery({
                            page: PageType.user_settings,
                            [`${PageType.user_settings} tab`]: Tabs[PageType.user_settings][0],
                          });
                          onClose();
                        }}
                      >
                        {'Sign up now to access this class'}
                      </Button>
                    </>
                  )}
                </Box>
              )}
            </Box>
            <AuthWrapper>
              <Box
                sx={{
                  width: '50%',
                  pl: 6,
                  ...(small && {
                    width: 1,
                    pl: 0,
                    mt: 3,
                  }),
                }}
              >
                <MemberAttendance session={session} date={classTime} />
              </Box>
            </AuthWrapper>
          </Box>
          <AuthWrapper>
            <Box ml={-2} mr={-5} mt={-5}>
              <IconButton size="small" onClick={() => setCreateModal(true)}>
                <EditIcon sx={{ height: 18, width: 18 }} />
              </IconButton>
            </Box>
          </AuthWrapper>
        </Card>
      </CustomModal>
    </>
  );
};
