import { Box, Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { useSchoolContext } from 'components/context/school-context';
import { useSchoolMember } from 'hooks/admin/useSchoolMember';
import { useMemo, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import CancelIcon from '@mui/icons-material/Cancel';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { MemberModal } from 'components/admin/member-settings/member-modal';
import { MemberFilter } from './member-filter';
import { ActiveStatus, CancelledStatus, statusOrder, SubscriptionParams } from 'utils/types';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import { useMutate } from 'hooks/useMutate';
import { useCohorts } from 'hooks/useCohorts';
import { useMembership } from 'hooks/useMemership';
import { UpdateSubscriptionModal } from '../update-subscription-modal';
import { EnrolCard } from './enrol-card';
import { EnrolmentButton } from 'components/shared/enrolment-button';
import { useUserEnrolments } from 'hooks/admin/useUserEnrolments';
import { keyBy } from 'lodash';
import { useSchoolMembers } from 'hooks/admin/useSchoolMembers';
import { useQueryState } from 'hooks/useQueryState';

export const Enrolments = () => {
  const { school } = useSchoolContext();
  const { cardWidth } = useCustomTheme();
  const { data: cohorts } = useCohorts(school.id);
  const { data: memberships } = useMembership(school.id);
  const [{ searchTerm = '', userId }, setQuery, remove] = useQueryState();
  const [memberModalOpen, setMemberModalOpen] = useState(false);
  const [anchor, setAnchor] = useState<HTMLButtonElement>();
  const { data: enrolments = [] } = useUserEnrolments(school.id);
  const { data: member } = useSchoolMember(school.id, +userId || undefined);
  const { data: schoolMembers } = useSchoolMembers(school.id);

  const [membershipFilters, setMembershipFilters] = useState<number[]>([]);
  const [statusFilters, setStatusFilters] = useState<string[]>([]);
  const [subscriptionUpdateOpen, setSubscriptionUpdateOpen] = useState(false);
  const filtersApplied = statusFilters.length || membershipFilters.length;

  const memberMap = useMemo(() => keyBy(schoolMembers, 'userId'), [schoolMembers]);
  const membershipMap = useMemo(() => keyBy(memberships, 'id'), [memberships]);

  const { update } = useMutate<{ subscriptionIds: string[]; data: SubscriptionParams }>(
    'enrolment/batch-update-subscription'
  );

  const statusfilteredMembers = useMemo(() => {
    return enrolments?.filter(s => (statusFilters.length ? statusFilters?.some(i => i === s.status) : true));
  }, [enrolments, statusFilters]);

  const filteredEnrolments = useMemo(() => {
    return statusfilteredMembers
      ?.map(e => ({ ...e, user: memberMap[e.userId]?.user }))
      ?.sort((a, b) => a.user?.firstName.localeCompare(b.user?.firstName))
      ?.sort((a, b) => statusOrder[a.status] - statusOrder[b.status])
      .filter(s =>
        membershipFilters.length
          ? !CancelledStatus.includes(s.status) && membershipFilters.includes(s?.membershipId)
          : true
      );
  }, [statusfilteredMembers, membershipFilters]);

  const searchedMembers = useMemo(
    () =>
      searchTerm
        ? filteredEnrolments?.filter(i =>
            [`${i.user?.firstName} ${i.user?.lastName}`, i.user?.lastName, i.user?.email].some(
              v =>
                searchTerm &&
                !!v?.toLowerCase().match(new RegExp(`(^${searchTerm.replace(/[^a-zA-Z0-9 ]/g, '')})`, 'g'))
            )
          ) || []
        : filteredEnrolments,
    [searchTerm, filteredEnrolments]
  );

  const handleSubscriptionUpdate = (data?: SubscriptionParams) => {
    if (filteredEnrolments && data) {
      update({
        subscriptionIds: filteredEnrolments
          .filter(e => ActiveStatus.includes(e.status) && membershipFilters.includes(e.membershipId))
          .map(e => e.subscriptionId)

          .flat(),
        data: data,
      });
    }
    setSubscriptionUpdateOpen(false);
  };

  if (!enrolments || !schoolMembers || !memberships) {
    return <></>;
  }

  return (
    <Box mb={24} maxWidth={1200} justifyContent={'center'}>
      <MemberModal open={memberModalOpen} onClose={() => setMemberModalOpen(false)} />
      <UpdateSubscriptionModal open={subscriptionUpdateOpen} onClose={handleSubscriptionUpdate} type="pause" />
      <Box mt={6} display={'flex'} width={1} justifyContent={'center'}>
        <Box>
          <Box>
            {!!membershipFilters?.length && !!filteredEnrolments?.length && (
              <Box width={cardWidth} flexWrap={'wrap'} justifyContent={'space-between'} textAlign={'center'}>
                <Button onClick={() => setSubscriptionUpdateOpen(true)}>
                  <PauseCircleOutlineIcon sx={{ mr: 2 }} />
                  {'Pause memberships for selected members'}
                </Button>
              </Box>
            )}
          </Box>
          <Box display={'flex'} sx={{ width: cardWidth }}>
            <IconButton sx={{ height: 38, width: 38, mt: 4 }} onClick={e => setAnchor(e.currentTarget)}>
              <TuneIcon sx={{ color: school.priColor }} />
            </IconButton>

            {cohorts && memberships && (
              <MemberFilter
                memberships={memberships}
                statusFilters={statusFilters}
                membershipFilters={membershipFilters}
                onStatusChange={setStatusFilters}
                onMembershipChange={setMembershipFilters}
                anchor={anchor}
                onClose={() => setAnchor(undefined)}
              />
            )}
            <TextField
              autoComplete="off"
              value={userId && member ? `${member?.user?.firstName} ${member?.user?.lastName}` : searchTerm}
              onChange={({ currentTarget }) => {
                console.log('currentTarget.value.toLowerCase()', currentTarget.value.toLowerCase());
                setQuery({ searchTerm: currentTarget.value.toLowerCase().replace(' ', '%20') });
              }}
              sx={{ width: cardWidth }}
              InputProps={{
                endAdornment: (
                  <>
                    {userId && (
                      <InputAdornment position="start">
                        <IconButton sx={{ mr: -4 }} onClick={() => remove(['userId'])}>
                          <CancelIcon />
                        </IconButton>
                      </InputAdornment>
                    )}
                    <InputAdornment position="end">
                      <IconButton sx={{ mr: -4 }}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </Box>
          <Box height={32}>
            {(!!filtersApplied || searchTerm) && (
              <Box display={'flex'} width={1} justifyContent={'center'}>
                <Button
                  variant="text"
                  onClick={() => {
                    setStatusFilters([]);
                    setMembershipFilters([]);
                    remove(['searchTerm']);
                  }}
                >
                  Reset filters
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {member && (
        <Grid container mb={24} maxWidth={1200} justifyContent={'center'}>
          <EnrolCard user={member.user} member={member} />
          {member.user?.children.map(c => (
            <EnrolCard key={c.id} member={member.children.find(i => i.userId === c.id)} user={c} />
          ))}
        </Grid>
      )}
      <Grid mb={24} container maxWidth={1200} justifyContent={'center'} alignContent={'start'}>
        {!member && (
          <>
            {searchedMembers?.map((e, idx) => (
              <Box key={idx} sx={{ m: 3 }}>
                <EnrolmentButton
                  member={memberMap[e.userId]}
                  membership={membershipMap[e.membershipId]}
                  enrolment={e}
                  onClick={() => setQuery({ userId: `${e.userId}` })}
                />
              </Box>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );
};
