import { Grid, Box, Typography, Button } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import useDraggableScroll from 'use-draggable-scroll';
import { useSchoolContext } from 'components/context/school-context';
import { MembershipCard } from 'components/shared/membership-card/membership-card';
import { Membership, useMembership } from 'hooks/useMemership';
import { CustomModal } from 'components/shared/custom-modal';
import { User } from 'hooks/useUser';
import { useMutate } from 'hooks/useMutate';
import { UserDetailsModal } from '../../../modals/user-details-modal';
import { StartTrialModal } from './start-trial-modal';
import { grey } from '@mui/material/colors';
import { TermsAndConditionsModal } from 'components/modals/terms-and-conditions-modal';

type EnrolData = {
  membership: Membership;
  trialEnd?: number;
  requirePayment?: boolean;
};

type Props = {
  open: boolean;
  user: User;
  onClose: () => void;
};

export const AdminEnrolmentModal = ({ open, user, onClose }: Props) => {
  const { school } = useSchoolContext();
  const ref = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref);
  const useMembershipResponse = useMembership(school.id);
  const availableMemberships = useMemo(() => useMembershipResponse.data, [useMembershipResponse.data]);
  const [modal, setModel] = useState({ terms: false, userDetails: false });
  const [enrolData, setEnrolData] = useState<EnrolData>();
  const [userDetailsPresent] = useState(
    !!(user.provider === 'parent' || user.phone) && !!user.emergencyContact && !!user.birthday
  );

  const { create, response } = useMutate<string>('admin/enrolment', {
    invalidate: ['enrolment'],
    onSuccess: () => setEnrolData(undefined),
    headers: { href: location.href },
  });

  const { update } = useMutate('user', {
    invalidate: ['member'],
  });

  useEffect(() => {
    if (!!response) {
      window.location.assign(response);
    }
  }, [response]);

  const handleEnrol = () => {
    if (!enrolData) return;
    create({ ...enrolData, userId: user.id, membershipId: enrolData.membership.id });
    setEnrolData(undefined);
  };

  const handleSubmit = (data: EnrolData) => {
    setEnrolData(data);
    if (userDetailsPresent && !data?.membership.document) {
      create({ ...enrolData, userId: user.id, membershipId: data.membership.id });
      setEnrolData(undefined);
    } else if (!userDetailsPresent) {
      setModel({ userDetails: true, terms: false });
    } else if (data?.membership.document) {
      setModel({ userDetails: false, terms: true });
    }
  };

  const reset = () => {
    setModel({ userDetails: false, terms: false });
    setEnrolData(undefined);
  };

  return (
    <>
      {enrolData && (
        <>
          <UserDetailsModal
            key={user.id}
            open={modal.userDetails}
            user={user}
            onSubmit={data => {
              if (data) {
                update(data);
              }
              if (!enrolData?.membership.document) {
                handleEnrol();
              } else {
                setModel({ userDetails: false, terms: true });
              }
            }}
            onClose={reset}
          />
          {enrolData?.membership.document && (
            <TermsAndConditionsModal
              open={modal.terms}
              onSubmit={() => handleEnrol()}
              onClose={reset}
              document={enrolData?.membership.document}
            />
          )}
        </>
      )}
      <CustomModal open={open} onClose={onClose}>
        <Grid container direction={'column'} m={'auto'}>
          <Grid container justifyContent="center">
            <Box
              sx={{
                overflow: 'scroll',
                display: 'flex',
                scrollSnapType: 'x mandatory',
              }}
              ref={ref}
              onMouseDown={onMouseDown}
            >
              {availableMemberships?.length ? (
                availableMemberships.map(m => (
                  <MembershipCard membership={m} key={m.id}>
                    <Box sx={{ mt: 2, pt: 1, borderTop: `solid 1px ${grey[300]}` }}>
                      <Box pt={1}>
                        <Typography variant="h5">{`Sign up ${user.firstName}`}</Typography>
                      </Box>
                      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-evenly' }}>
                        <Button
                          variant="contained"
                          key={user.id}
                          onClick={() => handleSubmit({ membership: m, requirePayment: false })}
                        >
                          {`Sign up`}
                        </Button>
                        {m.type !== 'class_pass' && (
                          <StartTrialModal onSubmit={d => handleSubmit({ ...d, membership: m })} />
                        )}
                      </Box>
                    </Box>
                  </MembershipCard>
                ))
              ) : (
                <Typography variant="h2" mb={4}>
                  No active memberships
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};
