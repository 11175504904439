import { Box, Button, Checkbox, Popover, Typography } from '@mui/material';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { Membership } from 'hooks/useMemership';
import { useRef } from 'react';
import { Status } from 'utils/types';

export type Filters = { cohorts: Record<number, boolean> };

type Props = {
  anchor?: Element;
  memberships: Membership[];
  statusFilters: string[];
  membershipFilters: number[];
  onStatusChange: (value: string[]) => void;
  onMembershipChange: (value: number[]) => void;
  onClose: () => void;
};

export const MemberFilter = ({
  anchor,
  memberships,
  statusFilters,
  membershipFilters,
  onStatusChange,
  onMembershipChange,
  onClose,
}: Props) => {
  const { cardWidth } = useCustomTheme();
  const ref = useRef(null);

  const handleMembershipChange = (key: number, value: boolean) => {
    const ids = value ? membershipFilters.concat([key]) : membershipFilters.filter(i => i !== key);
    onMembershipChange(ids);
  };

  const handleStatusChange = (key: string, value: boolean) => {
    const ids = value ? statusFilters.concat([key]) : statusFilters.filter(i => i !== key);
    onStatusChange(ids);
  };

  return (
    <Popover
      open={!!anchor}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box ref={ref} sx={{ width: cardWidth, p: 4 }}>
        <Typography>Membership status</Typography>
        <Box display={'flex'} flexWrap={'wrap'}>
          {Object.values(Status).map(s => (
            <Box key={s} display="flex" m={0.5}>
              <Checkbox
                checked={statusFilters.includes(s.toLowerCase())}
                onChange={e => handleStatusChange(s.toLowerCase(), e.currentTarget.checked)}
              />
              <Typography my="auto" variant="body2" mr={2} ml={0.5} sx={{ textTransform: 'capitalize' }}>
                {s.replace('_', ' ')}
              </Typography>
            </Box>
          ))}
        </Box>
        <Typography mt={2}>Memberships</Typography>
        <Box display={'flex'} flexWrap={'wrap'}>
          {memberships
            ?.filter(m => m.type === 'membership')
            .map(m => (
              <Box key={m.id} display="flex" m={0.5}>
                <Checkbox
                  checked={membershipFilters.includes(m.id)}
                  onChange={e => handleMembershipChange(m.id, e.currentTarget.checked)}
                />
                <Typography my="auto" variant="body2" mr={2} ml={0.5}>
                  {m.name}
                </Typography>
              </Box>
            ))}
        </Box>
        <Typography mt={2}>Group Memberships</Typography>
        <Box display={'flex'} flexWrap={'wrap'}>
          {memberships
            ?.filter(m => m.type === 'group_membership')
            .map(m => (
              <Box key={m.id} display="flex" m={0.5}>
                <Checkbox
                  checked={membershipFilters.includes(m.id)}
                  onChange={e => handleMembershipChange(m.id, e.currentTarget.checked)}
                />
                <Typography my="auto" variant="body2" mr={2} ml={0.5}>
                  {m.name}
                </Typography>
              </Box>
            ))}
        </Box>
        <Typography mt={2}>Class Passes</Typography>
        <Box display={'flex'} flexWrap={'wrap'}>
          {memberships
            ?.filter(m => m.type === 'class_pass')
            .map(m => (
              <Box key={m.id} display="flex" m={0.5}>
                <Checkbox
                  checked={membershipFilters.includes(m.id)}
                  onChange={e => handleMembershipChange(m.id, e.currentTarget.checked)}
                />
                <Typography my="auto" variant="body2" mr={2} ml={0.5}>
                  {m.name}
                </Typography>
              </Box>
            ))}
        </Box>
        <Box width={1} textAlign={'end'} mt={2}>
          <Button
            variant="text"
            onClick={() => {
              onStatusChange([]);
              onMembershipChange([]);
            }}
          >
            Reset
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};
