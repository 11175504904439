import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { BarChart } from '@mui/x-charts';
import { useSchoolContext } from 'components/context/school-context';
import { CardBase } from 'components/shared/card-base';
import { Attendance } from 'hooks/useAttendance';
// import { useClass } from 'hooks/useClass';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { chartPalette } from 'Theme';
import { ValueBox } from '../value-box';
import { iso } from 'utils/time';

type Props = {
  attendance: Attendance[];
};

export const Body = ({ attendance }: Props) => {
  const { small } = useCustomTheme();
  const { school } = useSchoolContext();
  // const { classes } = useClass(school.id);

  // const classMap = keyBy(classes, 'id');

  // const groups = useMemo(
  //   () =>
  //     groupBy(
  //       attendance.map(a => ({ ...a, class: classMap[a.classId] })),
  //       a => a.class?.subject[0]?.name
  //     ),
  //   [classes, classMap]
  // );

  const now = DateTime.now();
  const yearStart = now.startOf('year');
  const ranges = useMemo(
    () =>
      new Array(12).fill(0).map((_, i) => {
        const date = yearStart.plus({ months: i });
        return {
          date: date.monthShort,
          v1: attendance.filter(d => iso(d.date).year === date.year && iso(d.date).month === date.month).length,
          v2: attendance.filter(
            d => iso(d.date) <= date.minus({ year: 1 }) && iso(d.date) > date.minus({ months: 1, year: 1 })
          ).length,
        };
      }),
    [attendance]
  );

  const week = useMemo(
    () => attendance.filter(d => iso(d.date) <= now && iso(d.date) > now.minus({ days: 7 })).length,
    [attendance]
  );
  const month = useMemo(
    () => attendance.filter(d => iso(d.date) <= now && iso(d.date) > now.minus({ days: 30 })).length,
    [attendance]
  );

  return (
    <Box>
      <Box display={'flex'}>
        <ValueBox title={'Last 7 days'} value={`${week || 0}`} />
        <ValueBox title={'Last 30 days'} value={`${month || 0}`} />
      </Box>
      <CardBase sx={{ p: 3, height: 320, maxWidth: 600, width: small ? '92vw' : '60vw', m: 3 }}>
        <Box mt={1}>
          <Typography mb={0} color={grey[600]}>
            {'Attendance'}
          </Typography>
          <BarChart
            dataset={ranges}
            yAxis={[
              {
                scaleType: 'linear',
              },
            ]}
            xAxis={[
              {
                scaleType: 'band',
                dataKey: 'date',
              },
            ]}
            colors={[school.priColor, ...chartPalette]}
            height={260}
            sx={{ pr: 0 }}
            series={[
              {
                data: ranges.map(i => i.v1),
                label: now.year.toString(),
              },
              {
                data: ranges.map(i => i.v2),
                label: now.minus({ year: 1 }).year.toString(),
              },
            ]}
            borderRadius={10}
            margin={{
              left: 40,
              right: 10,
              top: 60,
              bottom: 20,
            }}
            slotProps={{
              legend: {
                hidden: false,
                labelStyle: {
                  fontSize: 10,
                },
                itemMarkWidth: 8,
                itemMarkHeight: 8,
                markGap: 5,
                itemGap: 15,
                position: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              },
            }}
          />
        </Box>
      </CardBase>
    </Box>
  );
};
