import { useSchoolContext } from 'components/context/school-context';
import { LoadingSpinner } from 'components/shared/loading-spinner';
import { ScrollContainer } from 'components/shared/scroll-container';
import { Resource, useResource } from 'hooks/useResource';
import { ResourceModal } from './modal';
import { useState } from 'react';
import { IconButton, Box, Typography } from '@mui/material';
import { ModalBase } from 'components/shared/modal-base';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { AddButton } from 'components/shared/add-button';
import { BrandButton } from 'components/shared/brand-button';
import { THEME } from 'Theme';
import { useCustomTheme } from 'hooks/useCustomTheme';

export const Resources = () => {
  const { school } = useSchoolContext();
  const { cardWidth } = useCustomTheme();
  const [open, setOpen] = useState(false);
  const response = useResource(school.id);
  const [resource, setResource] = useState<Resource>();
  const [openResource, setOpenRessource] = useState<Resource>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClick = (r: Resource) => {
    window.open(r.url, '_blank');
  };

  const handleClose = () => {
    setOpen(false);
    setResource(undefined);
    setOpenRessource(undefined);
  };

  if (response.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <ResourceModal key={resource?.id} resource={resource} open={open || !!resource} onClose={handleClose} />
      <ScrollContainer>
        <>
          {openResource && (
            <ModalBase key={openResource.id} open title={openResource.name} onClose={() => setOpenRessource(undefined)}>
              <Box>
                <Box mr={-3} mt={-7} textAlign={'right'}>
                  <IconButton size="small" onClick={() => setResource(openResource)}>
                    <EditIcon sx={{ height: 18, width: 18 }} />
                  </IconButton>
                </Box>
                <Box mt={6} width={1}>
                  <Box>
                    <BrandButton sx={{ width: 1 }} onClick={() => handleClick(openResource)}>
                      {'Open link'}
                    </BrandButton>
                  </Box>
                  {openResource.description && (
                    <Typography sx={{ mt: 4 }} variant="h4">
                      {openResource.description}
                    </Typography>
                  )}
                </Box>
              </Box>
            </ModalBase>
          )}
          {response.data?.map(r => (
            <Box m={3}>
              <Box display={'flex'}>
                <BrandButton onClick={() => setOpenRessource(r)}>
                  <Box width={1} sx={{ display: 'flex', justifyContent: 'space-between', px: 2 }}>
                    <Box my={'auto'}>{r.name}</Box>
                  </Box>
                </BrandButton>
                <IconButton sx={{ borderRadius: 3, ml: -12 }} size="small" onClick={() => handleClick(r)}>
                  <ArrowForwardIcon sx={{ color: THEME.palette.getContrastText(school.priColor) }} fontSize="large" />
                </IconButton>
              </Box>
            </Box>
          ))}
          <AddButton sx={{ m: 3, width: cardWidth }} label="Add link" onClick={() => setOpen(true)} />
        </>
      </ScrollContainer>
    </>
  );
};
