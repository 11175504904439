import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

export type Resource = {
  id: number;
  name: string;
  url: string;
  description: string;
  schoolId: number;
};

export const useResource = (schoolId: number): UseQueryResult<Resource[]> => {
  const queryResponse = useQuery<any, AxiosError>(
    ['resource', schoolId],
    async () => {
      const response = await axios.get('/resource', { headers: { school: schoolId } });
      return response.data;
    },
    {
      enabled: !!schoolId,
    }
  );

  return queryResponse;
};
