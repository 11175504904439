import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CardBase } from 'components/shared/card-base';

export const ValueBox = ({ title, value }: { title: string; value: string | number }) => {
  return (
    <Box p={3} width={'50%'} textAlign={'center'}>
      <Typography mb={1}>{title}</Typography>
      <CardBase sx={{ width: 1 }}>
        <Box sx={{ display: 'flex', height: 50, textAlign: 'center' }}>
          <Typography m={'auto'} variant="h2">
            {value}
          </Typography>
        </Box>
      </CardBase>
    </Box>
  );
};
