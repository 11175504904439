import { Box, Button, Checkbox, Popover, Typography } from '@mui/material';
import { Cohort } from 'hooks/useCohorts';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { useRef } from 'react';

export type Filters = { cohorts: Record<number, boolean> };

type Props = {
  anchor?: Element;
  cohorts: Cohort[];
  cohortFilters: number[];
  statusFilters: string[];
  onCohortChange: (value: number[]) => void;
  onStatusChange: (value: string[]) => void;
  onClose: () => void;
};

export const MemberFilter = ({
  anchor,
  cohorts,
  cohortFilters,
  statusFilters,
  onCohortChange,
  onStatusChange,
  onClose,
}: Props) => {
  const { cardWidth } = useCustomTheme();
  const ref = useRef(null);

  const handleCohortChange = (key: number, value: boolean) => {
    const ids = value ? cohortFilters.concat([key]) : cohortFilters.filter(i => i !== key);
    onCohortChange(ids);
  };

  const handleStatusChange = (key: string, value: boolean) => {
    const ids = value ? statusFilters.concat([key]) : statusFilters.filter(i => i !== key);
    onStatusChange(ids);
  };

  return (
    <Popover
      open={!!anchor}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box ref={ref} sx={{ width: cardWidth, p: 4 }}>
        <Typography>Member status</Typography>
        <Box display={'flex'} flexWrap={'wrap'}>
          {['Active', 'Inactive', 'Prospect'].map(s => (
            <Box key={s} display="flex" m={0.5}>
              <Checkbox
                checked={statusFilters.includes(s.toLowerCase())}
                onChange={e => handleStatusChange(s.toLowerCase(), e.currentTarget.checked)}
              />
              <Typography my="auto" variant="body2" mr={2} ml={0.5}>
                {s}
              </Typography>
            </Box>
          ))}
        </Box>
        <Typography>Grades</Typography>
        <Box display={'flex'} flexWrap={'wrap'}>
          {cohorts?.map(c => (
            <Box key={c.id} display="flex" m={0.5}>
              <Checkbox
                checked={cohortFilters.includes(c.id)}
                onChange={e => handleCohortChange(c.id, e.currentTarget.checked)}
              />
              <Typography my="auto" variant="body2" mr={2} ml={0.5}>
                {c.name}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box width={1} textAlign={'end'} mt={2}>
          <Button
            variant="text"
            onClick={() => {
              onCohortChange([]);
              onStatusChange([]);
            }}
          >
            Reset
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};
