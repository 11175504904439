import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

export type Document = {
  id: number;
  name: string;
  description: string;
  url: string;
  version: number;
};

export const useDocument = (schoolId: number): UseQueryResult<Document[]> => {
  const queryResponse = useQuery<any, AxiosError>(
    ['document', schoolId],
    async () => {
      const response = await axios.get('/document', { headers: { school: schoolId } });
      return response.data;
    },
    {
      enabled: !!schoolId,
    }
  );

  return queryResponse;
};
