import { createTheme } from '@mui/material/styles';
import { green, grey } from '@mui/material/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const THEME = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0094A7',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#00384C',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#ff9800',
    },
    error: {
      main: '#f44336',
    },
    success: {
      main: green[500],
    },
    contrastThreshold: 2.2,
  },

  components: {
    MuiPaper: {
      defaultProps: {
        sx: { borderRadius: 3 },
      },
    },
    MuiCssBaseline: {},
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          borderRadius: 50,
          paddingBottom: 4,
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        sx: { fontSize: '16px', borderRadius: 3, backgroundColor: '#ffffff' },
      },
    },
    MuiInput: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        variant: 'outlined',
        fullWidth: true,
        margin: 'normal',
        sx: { fontSize: '16px', backgroundColor: 'white', borderRadius: 3 },
      },
    },
    MuiMenu: {
      defaultProps: {
        sx: {
          maxHeight: 390,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        style: {
          borderRadius: 12,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <RadioButtonUncheckedIcon />,
        checkedIcon: <CheckCircleIcon />,
        sx: { borderRadius: 3, p: 0 },
      },
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
    fontSize: 14,
    fontWeightRegular: 400,
    fontWeightBold: 500,
    button: {
      textTransform: 'none',
    },
    h1: {
      fontWeight: 800,
      fontSize: 27,
      textAlign: 'center',
    },
    h2: {
      fontWeight: 700,
      fontSize: 17,
      textAlign: 'center',
    },
    h3: {
      fontWeight: 600,
      fontSize: 14,
    },
    h4: {
      fontWeight: 500,
      fontSize: 14,
    },
    h5: {
      fontWeight: 500,
      fontSize: 14,
      color: grey[500],
    },
    subtitle1: {
      fontWeight: 800,
      fontSize: 12,
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 14,
    },
    body1: {
      fontWeight: 500,
      fontSize: 14,
    },
    body2: {
      fontWeight: 500,
      fontSize: 12,
    },
  },
  spacing: (value: number) => value * 4,
});

const createCustomTheme = (pri: string, sec: string, ter: string) =>
  createTheme({
    ...THEME,
    ...{
      palette: {
        ...THEME.palette,
        primary: {
          main: pri,
          dark: sec,
        },
        secondary: {
          main: ter,
        },
      },
    },
  });

const hexToRGB = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};

const shadeColor = (color: string, percent: number) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = (R * (100 + percent)) / 100;
  G = (G * (100 + percent)) / 100;
  B = (B * (100 + percent)) / 100;

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

hexToRGB('#FF0000', 0.5);

const contrast = (color: string) => THEME.palette.getContrastText(color);

const chartPalette: string[] = [
  '#0094A7',
  '#00ffe1',
  '#00ff84',
  '#ff9800',
  '#ff6600',
  '#c6fd00',
  '#f6ff00',
  '#d900ff',
  '#f099ff',
  '#0062ff',
  '#ff00e1',
  '#9a0164',
  '#7e019a',
  '#27019a',
  '#016c9a',
  '#00384C',
];

export { THEME, createCustomTheme, hexToRGB, shadeColor, chartPalette, contrast };
